/* istanbul ignore file */
const whatRgx = /<p>What +(.*?)</;
const whereRgx = /<br \/>Where +(.*?)</;
const whoRgx = /<br \/>Who +(.*?)</;
const fullRgx = /<p>(.*?)<\/p>/;

const bodyParser = (oldBody) => {
  if (typeof oldBody !== 'string') {
    return '';
  }

  const body = oldBody
    .replace(/\n|\r/g, '')
    .replace(/&#8217;/g, "'")
    .replace(/&#8216;/g, "'")
    .replace(/&#8211;/g, '–')
    .replace(/<\/?p[^>]*>/g, '');

  // Check if the What section exists and if other sections exist
  // if (body.match(whatRgx) === null) {
  //   return {
  //     what: body.match(fullRgx) ? body.match(fullRgx)[1] : '',
  //   };
  // }

  if (body.match(fullRgx) === null) {
    return {
      what: '',
      where: '',
      who: '',
    };
  }
  return {
    what: body.match(whatRgx) ? body.match(whatRgx)[1] : '',
    where: body.match(whereRgx) ? body.match(whereRgx)[1] : '',
    who: body.match(whoRgx) ? body.match(whoRgx)[1] : '',
  };

  // return {
  //   what: body.match(whatRgx)[1],
  //   where: body.match(whereRgx) ? body.match(whereRgx)[1] : '',
  //   who: body.match(whoRgx) ? body.match(whoRgx)[1] : '',
  // };
};

module.exports = bodyParser;
